/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:f634a957-b407-421c-8c01-50984aa91aa3",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_krdRv8yrP",
    "aws_user_pools_web_client_id": "7uoem3ju474mhm8foabauu5hf0",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://4gmwbhkhhzdornugknic3grx2m.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "MiaB",
            "endpoint": "https://uad26a1ul3.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "gatewayTable-dev",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
